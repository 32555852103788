<template>
  <div class="ap-list_paddings ap-list">
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <div v-for="(pendingAppointments, key) in formatterItems" :key="key" class="ap-list">
      <h1 class="ap-list_wrapTitle">{{ formaWrapTitle(key) }}</h1>
      <AppointmentsListItem
        v-for="appointment in pendingAppointments"
        :key="appointment.id"
        :item="appointment"
        place="pending"
        role="practitioner"
        @onButtonClick="joinRoom"
      />
    </div>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import { mapActions, mapState } from "pinia";

import AppointmentsListItem from "@/components/AppointmentsListItem";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";

export default {
  name: "Draft",
  components: { AppointmentsListItem },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions(useEncountersStore, ["getPendedEncounters"]),
    joinRoom(encounterId) {
      this.$router.push(`/${this.role}/encounters/${encounterId}`);
    },
    formaWrapTitle(date) {
      return this.$moment(date).format("dddd, DD MMMM YYYY");
    },
  },
  computed: {
    ...mapState(useAuthStore, ["role"]),
    ...mapState(useEncountersStore, ["pendedEncounters"]),
    formatterItems() {
      return groupBy(
        (this.pendedEncounters || []).map((i) => ({ ...i, _group: this.$moment(i.start).format("YYYY-MM-DD") })),
        (i) => i._group,
      );
    },
  },
  async mounted() {
    this.loading = true;
    await this.getPendedEncounters();
    this.loading = false;
  },
};
</script>

<style scoped></style>
